import { Controller } from "@hotwired/stimulus"

export default class VisibilityToggleController extends Controller {
  // ---
  // Targets
  static targets = ["hidable"]
  // ---
  // Values
  static values = { visible: Boolean }
  
  // ---
  // Classes
  static classes = ["hidden"]
  
  // ---
  // Actions
  toggle() {
    console.log("aaa")
    this.visibleValue = !this.visibleValue
  }
    
  visibleValueChanged() {
    this.elementToHideTarget.classList.toggle(this.hiddenClass, !this.visibleValue)
  } 
}